import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { startNFCScan } from "@src/appV2/Nfc/utils/nfcReader";
import { NfcReaderError } from "@src/appV2/Nfc/utils/nfcReaderError";
import { useMemo, useState } from "react";

export function NfcPluginDebugger() {
  const scanner = useMemo(() => startNFCScan(), []);

  const modalState = useModalState(ModalStatus.CLOSED);

  const [readValue, setReadValue] = useState<string>();
  const [readError, setReadError] = useState<string>();
  const [readerState, setReaderState] = useState<"idle" | "reading" | "stopped">("idle");

  const startReading = () => {
    setReaderState("reading");

    scanner
      .readOne(10_000)
      .then((readValue) => {
        setReaderState("idle");
        setReadValue(readValue);
      })
      .catch((error) => {
        setReaderState("idle");
        if (error instanceof NfcReaderError) {
          setReadError(`Known: ${error.type}`);
          return;
        }

        setReadError(`Unknown: ${error?.message as string}`);
      });
  };

  return (
    <>
      <Button variant="outlined" onClick={modalState.openModal}>
        Open NFC debugger
      </Button>
      <FullScreenDialog modalState={modalState}>
        <DialogContent>
          <Stack spacing={2}>
            <Text variant="h2">NFC Debugger</Text>
            <Text>State: {readerState}</Text>
            <Text>Read Value: ({readValue ?? "undefined"})</Text>
            <Text>Error: ({readError ?? "undefined"})</Text>

            <Button
              variant="contained"
              onClick={() => {
                startReading();
              }}
            >
              Start reader
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                void scanner.cancel();
                setReaderState("stopped");
              }}
            >
              Stop reader
            </Button>
            <Button variant="outlined" color="secondary" onClick={modalState.closeModal}>
              Close
            </Button>
          </Stack>
        </DialogContent>
      </FullScreenDialog>
    </>
  );
}
